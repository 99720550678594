import React, { useEffect ,useState} from "react"
import { Link } from "gatsby"
import "@reach/dialog/styles.css"
import Layout from "components/layout"
import SEO from "components/seo"
import PaddedContent from "components/paddedContent"
import WavyBackground from "components/wavyBackground"
import styles from "styles/templates/article.module.scss"
import jobstyles from "styles/templates/jobs.module.scss"
import { getQueryString } from "variables.js"
import {queryGreenhouseJob} from "hooks/use-greenhouseJob"

const Article = () => {
    // EC-8656: Incorrect job shown on job detail page
    const [gh_Id, setID] = useState(null);
    const [title, setTitle] = useState(null);

    const [location, setLocation] = useState({name:""});
    let maxHeight = "890px"
    useEffect(() => {
        const isBrowser = typeof window != "undefined";
        // EC-13751: Job links redirect back to job board
        if (isBrowser) {
            if (window.location.search) {
                const id = getQueryString("job_id")
                queryGreenhouseJob(id).then(response => {
                    setTitle(response.title)
                    setLocation(response.location)
    
                    var doc = new DOMParser().parseFromString(response.content, 'text/html');
                    document.getElementById("job-content").innerHTML = doc.documentElement.textContent;
                }).catch(error => {
                    console.error(error)
                });
                setID(id);
            } else {
                window.location.href = "/about/careers/jobs/";
            }
        }
    }, []);
      
    maxHeight = gh_Id=="4330691007" ? "2520px":"890px"

    return (
        <Layout color="navy" headerPadding>
            <SEO
                title={title}
                description={location.name}
                slug={`/jobs/${gh_Id}`}
            />

            <article>
                <PaddedContent>
                    <div className={styles.headerContainer}>
                        <div className={jobstyles.titleContainer}>
                            <div>
                                <h1>{title}</h1>
                                <p className={jobstyles.subTitle}>at Encompass Technologies (<Link to="/about/careers/jobs/" className={jobstyles.link}>View all jobs</Link>)</p>
                                <p className={jobstyles.locationLabel}>{location.name}</p>
                            </div>
                            <a href="#request-form" className={jobstyles.buttonWrapper}>Apply Now</a>
                        </div>

                        <div id="job-content"></div>
                    </div>

                    <iframe id="request-form" width="100%" height={maxHeight} frameBorder="0" src={`https://boards.greenhouse.io/encompasstechnologiesllp/jobs/${gh_Id}`}>

                    </iframe>
                </PaddedContent>
            </article>
            <WavyBackground />
        </Layout>
    )
}

export default Article

// export const query = graphql`
//   query JobByID($id: Float) {
//     allGreenhouseJob(filter: {gh_Id: {eq: $id}}) {
//         nodes {
//             location {
//                 name
//             }
//             title
//             gh_Id
//             content
//         }
//     }
//   }
// `
